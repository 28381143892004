import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`船越雅代`}</h1>
    <p>{`瓜、オクラ、トウモロコシにソウメンカボチャ。薄くスライスされた様々な野菜の断面が、何層にも重ねられています。料理家でアーティストの船越雅代さんは、井口直人さんがお気に入りのイメージを重ね、楽しそうにコピーを取る様子に応答し、ご自身の大好きな食材である野菜をふんだんに使った作品（料理）を作りました。紫蘇やオクラの花を煮出して作った色水に浸され、ほんのり色づいた野菜たち。その生き生きとした姿から、船越さんの愛情が伝わってきます。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHIoQ+bhAoP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBAxESIf/aAAgBAQABBQJRquFb6zY84k//xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQMBAT8BgZ//xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQIBAT8Bq5//xAAZEAACAwEAAAAAAAAAAAAAAAAQMQABESH/2gAIAQEABj8CWzb4Ez//xAAZEAADAQEBAAAAAAAAAAAAAAAAARExQWH/2gAIAQEAAT8hVN6bg6YUMlawQPTR/9oADAMBAAIAAwAAABDrz//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EFD/xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQIBAT8QeTP/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhQVExYXH/2gAIAQEAAT8QcXxE0O1hDgCCeM98ylwlVYbXSR9dFU+GZFs6yf/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/380351d20343b637a593b309d35b4f87/ec4f2/masayo-funakoshi.webp 320w", "/static/380351d20343b637a593b309d35b4f87/74ed4/masayo-funakoshi.webp 640w", "/static/380351d20343b637a593b309d35b4f87/265b4/masayo-funakoshi.webp 1280w", "/static/380351d20343b637a593b309d35b4f87/ed2e8/masayo-funakoshi.webp 1920w", "/static/380351d20343b637a593b309d35b4f87/6b78f/masayo-funakoshi.webp 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/380351d20343b637a593b309d35b4f87/9328e/masayo-funakoshi.jpg 320w", "/static/380351d20343b637a593b309d35b4f87/f24a5/masayo-funakoshi.jpg 640w", "/static/380351d20343b637a593b309d35b4f87/36316/masayo-funakoshi.jpg 1280w", "/static/380351d20343b637a593b309d35b4f87/18346/masayo-funakoshi.jpg 1920w", "/static/380351d20343b637a593b309d35b4f87/29666/masayo-funakoshi.jpg 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/380351d20343b637a593b309d35b4f87/36316/masayo-funakoshi.jpg",
          "alt": "masayo funakoshi",
          "title": "masayo funakoshi",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <Caption before={`作品`} medium={`食、2020年`} mdxType="Caption">
  《痕跡》
    </Caption>
    <BpHr type={`dot`} color={`invert`} mdxType="BpHr" />
    <LinkButton color={`invert`} to={`/works/naoto-iguchi`} mdxType="LinkButton">
  作品: 井口直人
    </LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      